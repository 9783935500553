import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { BigNumber, BigNumberish, ethers } from "ethers";
import "./styles/index.scss";
import "./MediaQueries.css";
import Home from "./pages/Home";
import SessionDetail from "./pages/SessionDetail";
import SessionsTemplate from "./pages/Sessions";
import SessionsList from "./pages/SessionsList";
import NewSession from "./pages/NewSession";
import UserProfile from "./pages/UserProfile";
import EditProfile from "./pages/EditProfile";

import {
  createContractInstance,
  getProvider,
  isAllowed,
  isWalletAvailable,
  promptCountByAccount,
  promptLimitPerAccount,
} from "src/services/ethereum";
import { ensureNotificationVisibleIf } from "./utils/dom";
import { Network, SwitchError, WalletChain } from "./types/Chain";
import { getNetworkById, getNetworkList, numberAsHexString } from "./utils/network";
import { AVAILABLE_NETWORK_CHAIN_IDS, NETWORKS } from "./constants";
import Main from "./pages/Main";
import { NetworkContext } from "./contexts/network";
import { AccountInfo, DEFAULT_NETWORK_NAME } from "./shared/prompt";
import { NetworkContextType } from "./types/NetworkContext";
import { UserProfilesCache, UserProfilesContextType } from "./types/UserProfilesContext";
import { fetchUserDetails } from "./services/cloudflare";
import { UserProfilesContext } from "./contexts/userProfiles";

const SITES = {
  production: {
    main: "prompts.studio",
    exquisitecorpse: "exquisitecorpse.prompts.studio",
  },
  development: {
    main: "localhost",
    exquisitecorpse: "127.0.0.1",
  },
};

function App() {
  return (
    <div className="App">
      <UserProfilesContext.Provider value={null}>
        <NetworkContext.Provider value={null}>
          <Main isProduction={true} sites={SITES} />
        </NetworkContext.Provider>
      </UserProfilesContext.Provider>
    </div>
  );
}

export default App;
