import { Network } from "./types/Chain";

export const CANVAS_WIDTH = 384;
export const CANVAS_CONTRIBUTION_HEIGHT = 128;
export const CANVAS_HEIGHT = CANVAS_CONTRIBUTION_HEIGHT * 3;
export const CANVAS_WIDTH_LG = 768;
export const CANVAS_CONTRIBUTION_HEIGHT_LG = 256;
export const CANVAS_HEIGHT_LG = CANVAS_CONTRIBUTION_HEIGHT_LG * 3;

export const PIXEL_DROP_SIZE = 8;
export const PIXEL_DPI = 1;

export const LAST_USED_COLORS_LOCAL_STORAGE_KEY = "last-used-colors";
export const COLOR_PRESETS_LOCAL_STORAGE_KEY = "color-presets";

export const BLOCKTIME_ENDPOINT = "https://prompts-api.prompts.workers.dev/api/blocktime";
export const CACHE_ENDPOINT = "https://prompts-api.prompts.workers.dev/api/";
export const CACHE_INVALIDATION_ENDPOINT = "https://prompts-api.prompts.workers.dev/api/refresh";

export const AVAILABLE_NETWORK_CHAIN_IDS = [3, 43113];
export const CACHE_ENABLED = true;

export const NETWORKS: Record<string, Network> = {
  goerli: {
    chainId: 5,
    chainName: "Goerli",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://goerli.etherscan.io"]
  },
  rinkeby: {
    chainId: 4,
    chainName: "Rinkeby",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://rinkeby.etherscan.io"]
  },
  homestead: {
    chainId: 1,
    chainName: "Ethereum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://etherscan.io"]
  },
  fuji: {
    chainId: 43113,
    chainName: "Fuji",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18
    },
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://testnet.snowtrace.io"]
  },
  avalanche: {
    chainId: 43114,
    chainName: "Avalanche",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"]
  },
  maticmum: {
    chainId: 80001,
    chainName: "Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
  },
  matic: {
    chainId: 137,
    chainName: "Polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"]
  }
};
