import { FC } from "react";
import { GoLinkExternal } from "react-icons/go";

export const LinkExternal: FC<{
  to: string;
  text: string;
}> = ({ to, text }) => {
  return (
    <a target="_blank" rel="noreferrer" href={to} className="link-dark">
      {text}
      <GoLinkExternal className="ms-1" />
    </a>
  );
};

export default LinkExternal;
