export const DEFAULT_NETWORK_NAME = "homestead";

export type AccountInfo = {
  name: string,
  username: string,
  address: string,
  website: string,
  profileImageUrl: string,
};

export type AccountInfoWithSignature = AccountInfo & {
  signature: string;
}

export const whitelist = "abcdefghijklmonprstuyzxw1234567890_.";

export const CONTRACT_ADDRESSES: Record<string, string> = {
  fuji: "0x7237DF2d6Ad9599f5919421a0A70A02601e641F1",
  ropsten: "0x3d60c48bf526F4F74567C79d178BD58016f55F49",
  rinkeby: "0x3c87cB72f329a5358888C7669BE347F3d4c80679",
  homestead: "0xa13d5470611fedbD9591C25527E5096C25986770",
};

export enum PromptFilterName {
  All,
  InProgress,
  Unfinished,
  Available,
  Minted,
}

export type HexValue = {
  _hex: string;
  _isBigNumber: boolean;
};

export type Contribution = {
  url: string | undefined;
  createdAt: string;
  contributor: string;
  price: number;
};

export type FileContentIpfs = {
  image: string;
  image_png: string;
  name: string;
  description: string;
}

export type Prompt = {
  owner: string;
  endsAt: number;
  tokenURI: string;
  members: string[];
  contributions: Contribution[];
  promptId: number;
};
export type UsernameAvailabilityResponse = "available" | "same-username" | "not-available";
export type PromptResponse = {
  "0": string; // owner address
  "1": string; // endsAt
  "2": string; // tokenURI (filled after finalized)
  "3": string[]; // member addresses
  "4": [
    url: string,
    createdAt: string,
    contributorAddress: string,
    price: HexValue
  ][]; // contributions
};

export const filterPrompts = (
  prompts: Prompt[],
  filter: PromptFilterName,
  blockTime: number
): Prompt[] => {
  let filteredPrompts;
  switch (filter) {
    case PromptFilterName.All:
      filteredPrompts = prompts;
      break;
    case PromptFilterName.InProgress:
      filteredPrompts = prompts.filter((prompt) =>
        isPromptInProgress(prompt, blockTime)
      );
      break;
    case PromptFilterName.Unfinished:
      filteredPrompts = prompts.filter((prompt) =>
        isPromptUnfinished(prompt, blockTime)
      );
      break;
    case PromptFilterName.Available:
      filteredPrompts = prompts.filter((prompt) =>
        isPromptAvailable(prompt, blockTime)
      );
      break;
    case PromptFilterName.Minted:
      filteredPrompts = prompts.filter(isPromptMinted);
      break;
  }
  return filteredPrompts;
};

export const isPromptInProgress = (prompt: Prompt, blockTime: number) =>
  prompt.contributions.length < 3 && prompt.endsAt > blockTime;

export const isPromptUnfinished = (prompt: Prompt, blockTime: number) =>
  prompt.contributions.length < 3 && prompt.endsAt < blockTime;

export const isPromptAvailable = (prompt: Prompt, blockTime: number) =>
  (prompt.contributions.length === 3 || prompt.endsAt < blockTime) &&
  !prompt.tokenURI;

export const isPromptMinted = (prompt: Prompt) => Boolean(prompt.tokenURI);
