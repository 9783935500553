import { FC, useEffect } from "react";
import { NETWORKS } from "src/constants";
import { CONTRACT_ADDRESSES, DEFAULT_NETWORK_NAME } from "src/shared/prompt";
import LinkExternal from "src/components/LinkExternal";

const Main: FC<{
  isProduction: boolean;
  portNumber?: number;
  sites: {
    production: Record<string, string>;
    development: Record<string, string>;
  };
}> = ({ sites, portNumber = 8787, isProduction }) => {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  useEffect(() => {
    const document = window.document as any;
    const pageContainer = document.getElementById("pageContainer") as any;

    if (!pageContainer) {
      return;
    }

    pageContainer.classList.add("gradient");
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("gradient");
      document.body.classList.remove("bg-white");
    };
  }, []);

  const explorerLink =
    NETWORKS[DEFAULT_NETWORK_NAME].blockExplorerUrls[0] +
    "/address/" +
    CONTRACT_ADDRESSES[DEFAULT_NETWORK_NAME];

  return (
    <div className="explainer">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 fs-5 lead">
            <p>
              Prompts extend the ERC721 non-fungible token standard to enable
              time-bound verifiable collaborative authorship.
            </p>
            <div className="explainer-inner p-4 lead">
              Explore the first Prompts project{" "}
              <strong>
                {isProduction ? (
                  <a href={`https://${sites.production.exquisitecorpse}`}>
                    Exquisite Corpse
                  </a>
                ) : (
                  <a
                    href={`http://${sites.development.exquisitecorpse}:${portNumber}`}
                  >
                    Exquisite Corpse
                  </a>
                )}
              </strong>
            </div>
            <p>
              Every session is a group performance that generates a
              jointly-owned artifact in the form of an NFT. These event
              derivatives may then be carried into future sessions, traded and
              remixed to build up a referential conversation among successive
              prompt participants.
            </p>
            <p>
              <LinkExternal to={explorerLink} text="A Prompt smart contract" />
              includes several deployment parameters, duration, max supply,
              creation limit per account, as well as an allowlist function,
              which controls who can initiate a session and how collaborators
              may be added.
            </p>
            <p>
              While each multi-party session produces an artistic product, the
              Prompts token extension also emphasizes the performative aspects
              of art in the making. This project was inspired by Fluxus event
              scores in which poetic, mundane, or impossible, instructions could
              be variously performed or imagined. Likewise, we anticipate
              prompts may contain a multitude of instructions, whether absurd,
              aesthetic, or practical.
            </p>
            <p>
              Some imagined prompts include an artwork exchange ceremony, or an
              election in which the winner receives a signifying NFT.
            </p>
            <h4>Prompts Team</h4>
            <ul>
              <li>
                <LinkExternal
                  to="https://burak-arikan.com"
                  text="Burak Arikan"
                />
                , Founder
              </li>
              <li>
                <LinkExternal to="https://hxrts.com" text="Sam Hart" />, Founder
              </li>
              <li>
                <LinkExternal
                  to="https://fatih-erikli.com"
                  text="Fatih Erikli"
                />
                , Full-Stack Software Engineering
              </li>
              <li>
                <LinkExternal
                  to="https://ramazanerikli.com"
                  text="Ramazan Erikli"
                />
                , Front-End Software Engineering
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
